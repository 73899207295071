import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Topbar from "../components/Topbar";
import LeaderInProgram from '../components/LeaderInProgram';


const leaderInProgram = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School">
            <Topbar />
            <NavOne />
            <PageHeader title="Leader In Me Program" />
            <LeaderInProgram/>
            <Footer />
        </Layout>
    );
};

export default leaderInProgram;
