import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Topbar from "../components/Topbar";
import AstroClubActivities from '../components/AstroClubActivities';


const astroClubActivities = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School">
            <Topbar />
            <NavOne />
            <PageHeader title="Astro Club Activities" />
            <AstroClubActivities/>
            <Footer />
        </Layout>
    );
};

export default astroClubActivities;
